// ProjectsTab.tsx

import React, { useEffect, useRef, useState } from "react";
import { Box, HStack, Spacer, Spinner, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { Chat } from "../types";
import { getChats, setSelectedChatToken } from "../redux/chatsSlice";
import {
  BackgroundColor,
  BorderColor,
  Gray,
  Green,
  HoverBackgroundColor, Pink,
  SelectedBackgroundColor,
  TextColor
} from "../styles/colors";
import { setChatTicker } from "../redux/chatTickersSlice";
import { useNavigate } from "react-router-dom";
import api from "../auth/apiService";
import SimpleModal from "../modals/SimpleModal";
import { deleteMessages } from "../redux/chatMessagesSlice";


type Props = {};

type ProjectRow = {
  tickers?: string[];
  updatedAt: string;
  chatToken: string;
}

type FilingStatusCounts = {
  uploading_count: number;
  uploaded_count: number;
  failed_count: number;
  total_count: number;
}

// Define a JSON object for the upload status where key is chatToken and value is the upload status
type FilingsUploadStatus = {
  [chatToken: string]: FilingStatusCounts;
}

export const ProjectsTab: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const chats = useSelector((state: RootState) => state.chats.chats);
  const selectedChatToken = useSelector((state: RootState) => state.chats.selectedChatToken);
  const tickerMetadataList = useSelector((state: RootState) => state.tickerMetadata.tickerMetadataList);
  const [filingsUploadStatus, setFilingsUploadStatus] = useState<FilingsUploadStatus>({});

  // Context menu references
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: '0px', y: '0px' });
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [selectedProject, setSelectedProject] = useState<ProjectRow | null>(null);
  const [showClearChatModal, setShowClearChatModal] = useState(false);

  // Load chats from backend
  useEffect(() => {
    dispatch(getChats());
  }, [dispatch, selectedChatToken]);

  const handleSelectChat = (chatToken: string, chatTicker: string) => {
    dispatch(setSelectedChatToken(chatToken));

    const tickerMetadata = tickerMetadataList.find(tm => tm.ticker === chatTicker);
    if (tickerMetadata) {
      dispatch(setChatTicker(tickerMetadata));
      navigate(`?ticker=${tickerMetadata.ticker}`);
    }
  };

  // Poll the backend to check if any document is still uploading
  useEffect(() => {
    const intervalId = setInterval(() => {
      getFilingsUploadStatus();
    }, 5000); // 5 seconds

    // Cleanup: clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Load initial statuses from backend
  useEffect(() => {
    console.log("Fetching filings upload status");
    getFilingsUploadStatus();
  }, [chats, selectedChatToken]);

  // Handle showing and hiding of the right-click context menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeContextMenu();
      }
    };

    if (showContextMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showContextMenu]);

  const handleRightClick = (event: React.MouseEvent, row: ProjectRow) => {
    event.preventDefault();
    setSelectedProject(row);
    setShowContextMenu(true);
    setContextMenuPosition({ x: `${event.clientX}px`, y: `${event.clientY}px` });
  };

  const closeContextMenu = () => {
    setShowContextMenu(false);
  };

  const onCloseClearChatModal = () => {
    setShowClearChatModal(false)
    setSelectedProject(null);
  }

  const clearChatHistory = (row: ProjectRow) => {
    dispatch(deleteMessages({ chatToken: row.chatToken }));
    onCloseClearChatModal()
  }

  const getFilingsUploadStatus = async () => {
    const response = await api.get('knowledge/filings-upload-status')
    let uploadStatus = response.data.upload_status;

    setFilingsUploadStatus(uploadStatus);
  }

  const getProjectDate = (dateString: string | null): string => {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };

    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };

    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    return `${formattedDate}`;
  }

  const createRows = (chats: Chat[]): ProjectRow[] => {
    const rows: ProjectRow[] = [];
    chats.forEach(chat => {
      rows.push({
        chatToken: chat.token,
        tickers: chat.tickers,
        updatedAt: chat.updated_at,
      });
    });
    return rows;
  }

  const rows = createRows(chats);

  // TODO Get upload status of all Chats by ticker (look at KnowledgeTab.tsx)

  return (
    <Box
      maxHeight="calc(100vh - 250px)"
      overflowY="auto"
    >
      <Spacer height="10px"/>
      {rows.map(row => {
        return (
          <Box
            key={row.chatToken}
            cursor="pointer"
            onContextMenu={(event) => handleRightClick(event, row)}
            _hover={{ backgroundColor: HoverBackgroundColor }}
            background={row.chatToken === selectedChatToken ? SelectedBackgroundColor : BackgroundColor}
            onClick={() => {
              handleSelectChat(row.chatToken, row.tickers![0]);
            }}
          >
            <Box
              paddingX={2}
              paddingY={1}
            >
              <HStack>
                <VStack spacing={1} align="start">
                  <Text
                    fontSize='14px'
                    fontWeight='bold'
                    color={row.chatToken === selectedChatToken ? Green : TextColor}
                    isTruncated
                  >
                    {row.tickers && row.tickers.length > 0 ? row.tickers.join(', ') : 'New project'}
                  </Text>
                  <Text fontSize='14px' color={Gray} isTruncated>
                    Updated {getProjectDate(row.updatedAt)}
                  </Text>
                </VStack>
                <Spacer/>
                {filingsUploadStatus[row.chatToken]?.uploading_count > 0 && (
                  <Tooltip
                    label={`Uploaded ${filingsUploadStatus[row.chatToken]?.uploaded_count} out of ${filingsUploadStatus[row.chatToken]?.total_count} filings`}
                    borderRadius="20px"
                  >
                    <Spinner speed="0.67s" size={"sm"} color={Green}/>
                  </Tooltip>
                )}
              </HStack>
            </Box>
          </Box>
        )
      })}
      {showContextMenu && (
        <Box
          ref={menuRef}
          position="fixed"
          left={contextMenuPosition.x}
          top={contextMenuPosition.y}
          zIndex="popover"
          borderWidth="1px"
          borderColor={BorderColor}
          borderRadius="10px"
          minWidth="200px"
        >
          <Box
            _hover={{ bg: HoverBackgroundColor }}
            bg={BackgroundColor}
            padding="5px"
            cursor="pointer"
            borderRadius="10px"
            onClick={() => {
              if (selectedProject) {
                setShowClearChatModal(true);
              }
              closeContextMenu();
            }}
          >
            <Text
              marginLeft="12px"
              fontSize="14px"
              color={Pink}
            >
              Clear chat
            </Text>
          </Box>
        </Box>
      )}
      <Spacer height="20px"/>
      <SimpleModal
        isOpen={showClearChatModal}
        onClose={onCloseClearChatModal}
        modalBody="Are you sure you want to clear the chat?"
        modalTitle="Clear chat"
        primaryButtonText={"Yes"}
        onPrimaryButtonClicked={() => {
          if (selectedProject) {
            // TODO - clear the chat in BE
            clearChatHistory(selectedProject);
          }
        }}
      />
    </Box>
  )
}
