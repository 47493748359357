// RegisterPage.tsx
import { Box, Button, FormControl, FormLabel, Heading, Input, useToast, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { registerUser } from '../../redux/userSlice';
import { AppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import { AxiosError } from 'axios';
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { track } from "@vercel/analytics";
import { TextColor } from "../../styles/colors";

interface ErrorResponse {
  username: string | null;
  password: string | null;
}

export const RegisterPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    track('register-clicked', { location: 'register-page' });

    setIsLoading(true)
    try {
      await dispatch(registerUser({ username, password })).unwrap();
      setIsLoading(false)
      navigate('/');
    } catch (err) {
      const axiosError = err as AxiosError;
      showError(axiosError);
      setIsLoading(false)
    }
  }

  const showError = (axiosError: AxiosError<unknown, any>) => {
    if (axiosError.response) {
      const errorData = axiosError.response.data as ErrorResponse;
      let errorTitle = "Error";
      let errorMessage = "An error occurred. Please try again.";

      if (errorData.password) {
        errorTitle = "Password Error"
        errorMessage = errorData.password[0];
      } else if (errorData.username) {
        errorTitle = "Username Error"
        errorMessage = errorData.username[0];
      }

      toast({
        title: errorTitle,
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  return (
    <>
      <Button
        leftIcon={<FontAwesomeIcon icon={faArrowCircleLeft}/>}
        onClick={() => navigate('/')}
        position="absolute"
        top={4}
        left={4}
      >
        Back
      </Button>
      <Container>
        <Box bg="dark" minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
          <VStack as="form" onSubmit={handleSubmit} spacing={6} minWidth={"300px"}>
            <Heading as='h3' size='lg' color={TextColor}>Create an account</Heading>
            <FormControl isRequired>
              <FormLabel color={TextColor}>Username</FormLabel>
              <Input
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                borderRadius="20px"
                color={TextColor}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color={TextColor}>Password</FormLabel>
              <Input
                placeholder="Enter password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                borderRadius="20px"
                color={TextColor}
              />
            </FormControl>
            <Button type="submit" colorScheme="green" width="100%" isLoading={isLoading}>Submit</Button>
          </VStack>
        </Box>
      </Container>
    </>
  );
};

const Container = styled(Box)`
  flex: 1;
  overflow: auto; // new
`;