// tickerMetadataSlice.ts

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TickerMetadata } from "../types";
import api from "../auth/apiService";

export const getTickerMetadataList = createAsyncThunk<TickerMetadata[], void, {}>(
  'ticker-metadata',
  async () => {
    const response = await api.get(`/ticker-metadata/`);
    return response.data.ticker_metadata;
  }
);

type TickerMetadataState = {
  // All the tickers that we show in the TickerMetadataAutoComplete
  tickerMetadataList: TickerMetadata[],
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null | string
};

const initialState: TickerMetadataState = {
  tickerMetadataList: [],
  status: 'idle',
  error: null
};

export const tickerMetadataSlice = createSlice({
  name: 'ticker-metadata',
  initialState,
  reducers: {
    setTickerMetadataList: (state, action: PayloadAction<TickerMetadata[]>) => {
      state.tickerMetadataList = action.payload; // Replace the entire tickerMetadataList
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickerMetadataList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTickerMetadataList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tickerMetadataList = action.payload;
      })
      .addCase(getTickerMetadataList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? null;
      });
  },
});

export const { setTickerMetadataList } = tickerMetadataSlice.actions;

export default tickerMetadataSlice.reducer;
