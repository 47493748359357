import React from 'react';
import '../../styles/TypingIndicator.css'
import styled from "styled-components";
import { BackgroundColor } from "../../styles/colors";

interface TypingIndicatorProps {
  isTyping: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background: ${BackgroundColor};
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`;

const TypingIndicator: React.FC<TypingIndicatorProps> = ({isTyping}) => {
  if (!isTyping) return <div/>

  return (
    <Container>
      <InnerContainer>
        <div className={`typing-indicator-container ${isTyping ? 'visible' : 'hidden'}`}>
          <div className="typing-indicator-dot"/>
          <div className="typing-indicator-dot"/>
          <div className="typing-indicator-dot"/>
        </div>
      </InnerContainer>
    </Container>
  );
};

export default TypingIndicator;
