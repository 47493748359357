// chatsSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from "../auth/apiService";
import { Chat } from "../types";

export const getChats = createAsyncThunk<Chat[], void, {}>(
  'v2/chats',
  async () => {
    const response = await api.get(`/v2/chats`);
    return response.data;
  }
);

type ChatsState = {
  chats: Chat[],
  getChatsStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
  deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
  error: string | null,
  selectedChatToken: string | null,
};

const initialState: ChatsState = {
  chats: [],
  getChatsStatus: 'idle',
  deleteStatus: 'idle',
  error: null,
  selectedChatToken: null,
};

export const chatsSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setSelectedChatToken: (state, action) => {
      state.selectedChatToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChats.pending, (state) => {
        state.getChatsStatus = 'loading';
      })
      .addCase(getChats.fulfilled, (state, action) => {
        state.getChatsStatus = 'succeeded';
        state.chats = action.payload;
      })
      .addCase(getChats.rejected, (state, action) => {
        state.getChatsStatus = 'failed';
        state.error = action.error.message ?? null;
      })
  },
});
export default chatsSlice.reducer;

export const { setSelectedChatToken } = chatsSlice.actions;
