import React, { FC } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { TextColor } from "../styles/colors";

interface SimpleModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalBody: string;
  modalTitle: string;
  primaryButtonText: string;
  onPrimaryButtonClicked: () => void;
}

const SimpleModal: FC<SimpleModalProps> = ({
  isOpen,
  onClose,
  modalBody,
  modalTitle,
  primaryButtonText,
  onPrimaryButtonClicked,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent color={TextColor}>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            {modalBody}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onPrimaryButtonClicked} variant='outlinedPink' minWidth="100px">{primaryButtonText}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SimpleModal;