// ChatBox.tsx

import React from 'react';
import { Box } from "@chakra-ui/react";
import TypingIndicator from "./TypingIndicator";
import { ChatMessage } from "./ChatMessage";
import { Message } from '../../types';

type Props = {
  messages: Message[];
  isAssistantTyping: boolean;
};

export const ChatBox: React.FC<Props> = ({ messages, isAssistantTyping }) => {
  return (
    <Box flexGrow={1} overflowY="auto">
      {messages.map((message, index) => (
        <ChatMessage
          key={index}
          sender={message.sender}
          content={message.content}
          isUser={message.sender.toLowerCase() === 'user'}
          metadata={message.metadata}
          queries={message.queries}
        />
      ))}
      <TypingIndicator isTyping={isAssistantTyping}/>
      <Box height={"100px"}/>
    </Box>
  );
};
