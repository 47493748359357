// LeftSideBar.tsx

import React from "react";
import {
  Box,
  Divider,
  Flex,
  Link,
  Spacer,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import { BorderColor, Green, TextColor } from "../../../styles/colors";
import { store } from "../../../store";
import { logout } from "../../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import packageJson from '../../../../package.json';
import { ProjectsTab } from "../../../projects/ProjectsTab";

type LeftSideBarProps = {};

const LeftSideBar: React.FC<LeftSideBarProps> = ({}) => {
  const appVersion = packageJson.version;
  const navigate = useNavigate();

  const onLogoutClick = () => {
    store.dispatch(logout());
    navigate("/");
  };

  return (
    <Flex
      direction="column"
      justify="space-between"
      w="300px"
      borderRight="1px solid"
      borderColor={BorderColor}
    >
      <Box>
        <Tabs variant="unstyled" position="relative">
          <TabList paddingLeft="12px">
            <Tab fontSize="14px">Projects</Tab>
          </TabList>
          <TabIndicator height="2px" bg={TextColor}/>
          <Box>
            <TabPanels>
              <TabPanel>
                <ProjectsTab />
              </TabPanel>
              <TabPanel>
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Box>
      <Box maxHeight="200px">
        <Spacer height="20px"/>
        <Box paddingLeft="32px">
          <Link
            color={TextColor}
            _hover={{ color: Green }}
            display="inline"
            fontWeight="semibold"
            fontSize="14px"
            onClick={onLogoutClick}
          >
            Log out
          </Link>
        </Box>
        <Spacer height="20px"/>
        <Box paddingLeft="32px">
          <Text fontSize="xs" color="gray" fontWeight="semibold">
            v {appVersion}
          </Text>
        </Box>
        <Spacer height="20px"/>
      </Box>
    </Flex>
  );
};

export default LeftSideBar;
