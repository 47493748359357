// ChatLayout.tsx

import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { ChatBox } from "./ChatBox";
import { ChatInput } from "./ChatInput";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Message } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  getDefaultPrompt,
  getLanguageModelApiKey,
  getLanguageModelName,
  getLanguageModelProvider
} from "../../storage/LocalStorage";
import { useChatContext } from "../context/ChatContext";
import { getChatMessages } from "../../redux/chatMessagesSlice";

type Props = {};

const ChatLayout: React.FC<Props> = () => {
  // Get messages from Redux
  const chatMessages = useSelector((state: RootState) => state.chatMessages.messages);
  const tickerMetadata = useSelector((state: RootState) => state.chatTickers.selectedChatTicker);
  // Keep track of messages in a ref, so we can update them without re-rendering
  const messagesRef = useRef<Message[]>([]);
  // Only here to force a re-render when the messages change.  May want to use this in the future again.
  const [messages, setMessages] = useState<Message[]>([]);
  // Keep track of whether the assistant is typing
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  // Aborts the fetch request when the user clicks the "Stop generating" button
  const [abortController, setAbortController] = useState(new AbortController());
  const selectedChatToken = useSelector((state: RootState) => state.chats.selectedChatToken);
  const selectedChatTicker = useSelector((state: RootState) => state.chatTickers.selectedChatTicker);

  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    messagesRef.current = [...chatMessages];
    setMessages(chatMessages);
  }, [chatMessages]);

  useEffect(() => {
    dispatch(getChatMessages({chatToken: selectedChatToken, chatTicker: selectedChatTicker?.ticker}))
  }, [selectedChatToken])

  const onNewUserMessage = (message: Message) => {
    sendMessage(message)
  };

  const clearWebSocketConnection = () => {
    abortController.abort();
    setAbortController(new AbortController());
    setIsGeneratingResponse(false)
    setIsAssistantTyping(false)
  }

  const sendMessage = async (message: Message) => {
    setIsAssistantTyping(true);

    // Add the message to our local messages
    messagesRef.current.push(message)

    // TODO (virat) - ensure access token is not expired
    const accessToken = localStorage.getItem('access');
    const tickers = tickerMetadata ? [tickerMetadata.ticker] : [];

    // Send message via WebSocket
    await fetchEventSource(`${process.env.REACT_APP_BACKEND_API_URL}/send_message/`, {
      method: "POST",
      body: JSON.stringify({
        message: message.content,
        llm_api_key: getLanguageModelApiKey(),
        llm_provider: getLanguageModelProvider(),
        llm_model_name: getLanguageModelName(),
        prompt: getDefaultPrompt(),
        tickers: tickers,
        chat_token: selectedChatToken,
      }),
      headers: {
        Accept: "text/event-stream",
        Authorization: `Bearer ${accessToken}`,
      },
      openWhenHidden: true,
      async onopen(res) {
        setIsGeneratingResponse(true);

        // onopen is used to inspect the response object and can be used to check for client errors
        if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          toast({
            title: "Error",
            description: JSON.parse(await res.clone().text()).error,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
          setIsAssistantTyping(false);
        }
      },
      onmessage(event) {
        const data = event.data.trim();
        const json = JSON.parse(data);
        const assistantMessage = json["content"];
        const queries = json["queries"]
        const metadata = json["metadata"]

        // Check if assistantMessage is non-empty
        if (assistantMessage !== "") {
          setIsAssistantTyping(false);
        }

        // Copy the array to a new variable, so we don't mutate the state directly
        let newMessages = [...messagesRef.current];

        // Check if there are any messages in the array
        if (newMessages.length > 0) {
          // Get the last message in the array
          let lastMessage = newMessages[newMessages.length - 1];

          // Only append to the last message if it's from the AI
          if (lastMessage.sender === 'ASSISTANT') {
            // Append the incoming message to the content of the last message
            lastMessage.content = assistantMessage;
            lastMessage.metadata = metadata;
            lastMessage.queries = queries;

            // Replace the last message in the array with the updated last message
            newMessages[newMessages.length - 1] = lastMessage;
          } else {
            // If the last message is not from the AI, add a new message
            newMessages.push({ sender: 'ASSISTANT', content: assistantMessage, metadata: metadata, queries: queries });
          }
        } else {
          // If there are no previous messages, add a new message
          newMessages.push({ sender: 'ASSISTANT', content: assistantMessage, metadata: metadata, queries: queries });
        }

        // Update both the ref and the state
        messagesRef.current = newMessages;
        setMessages(newMessages);
      },
      onclose() {
        // onclose indicates that websocket connection has closed
        setIsAssistantTyping(false);
        setIsGeneratingResponse(false);
      },
      onerror(err) {
        setIsAssistantTyping(false);
        setIsGeneratingResponse(false);
        throw err;
      },
      signal: abortController.signal,
    });
  }

  // Pass sendMessage function to the context
  const { setSendMessageFn } = useChatContext();

  useEffect(() => {
    setSendMessageFn(sendMessage); // Set the sendMessage function in the global context
  }, [tickerMetadata, abortController]);

  // Get the window width and subtract 500px for the sidebars
  const windowWidth = `${window.innerWidth - 500}px`;
  return (
    <Flex flex="1" direction="column" maxW={windowWidth} position="relative">
      <Box flexGrow={1} overflowY="auto">
        <ChatBox messages={messagesRef.current} isAssistantTyping={isAssistantTyping}/>
      </Box>
      {(isGeneratingResponse || isAssistantTyping) && (
        <Button
          position="absolute"
          bottom="75px"
          right="8px"
          zIndex="10"
          onClick={() => {
            clearWebSocketConnection();
          }}
        >
          Stop generating
        </Button>
      )}
      <ChatInput onNewUserMessage={onNewUserMessage} isGeneratingResponse={isGeneratingResponse}/>
    </Flex>
  )
}

export default ChatLayout;
