// App.tsx

import React, { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import { Box, ChakraProvider } from "@chakra-ui/react";
import { Provider, useSelector } from "react-redux";
import { RootState, store } from "./store";
import ChatPage from "./chat/components/ChatPage";
import './styles/styles.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { LoginPage } from "./auth/components/LoginPage";
import { RegisterPage } from "./auth/components/RegisterPage";
import { loadUserFromLocalStorage } from "./auth/authService";
import { chakraTheme } from './styles/themes';
import { BackgroundColor } from "./styles/colors";
import { ChatContextProvider } from "./chat/context/ChatContext";
import { HomePage } from "./auth/components/HomePage";

const App: React.FC = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <Provider store={store}>
        <Router>
          <Home/>
        </Router>
      </Provider>
    </ChakraProvider>
  );
}

const Home = () => {
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user.user); // get the user state

  // Load the user if one exists
  useEffect(() => {
    loadUserFromLocalStorage();
  }, []);

  // If user is not logged in, redirect to LoginPage or RegisterPage
  if (user === null && !['/login', '/register'].includes(location.pathname)) {
    return (
      <Box>
        <Analytics/>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/register" element={<RegisterPage/>}/>
        </Routes>
      </Box>
    )
  }

  // If user is logged in, display Sidebar and set AgentPage as default
  return (
    <ChatContextProvider>
      <Box display='flex' bg={BackgroundColor}>
        <Analytics/>
        <Routes>
          <Route path="/" element={<ChatPage/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/register" element={<RegisterPage/>}/>
        </Routes>
      </Box>
    </ChatContextProvider>
  );
};

export default App;
