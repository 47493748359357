// ResearchTab.tsx

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import api from "../../../auth/apiService";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { TickerResearchTask } from "../../../types";
import { useChatContext } from "../../../chat/context/ChatContext";
import { Green, HoverBackgroundColor, TextColor } from "../../../styles/colors";

type Props = {};

export const ResearchTab: React.FC<Props> = () => {
  const selectedChatTicker = useSelector((state: RootState) => state.chatTickers.selectedChatTicker);
  const [businessTasks, setBusinessTasks] = useState<TickerResearchTask[]>([]);
  const [financialTasks, setFinancialTasks] = useState<TickerResearchTask[]>([]);
  const [managementTasks, setManagementTasks] = useState<TickerResearchTask[]>([]);
  const [competitorTasks, setCompetitorTasks] = useState<TickerResearchTask[]>([]);
  const [valuationTasks, setValuationTasks] = useState<TickerResearchTask[]>([]);

  useEffect(() => {
    // Get the first ticker in tickers list tickers has elements
    getResearchTasks(selectedChatTicker?.ticker);
  }, [selectedChatTicker]);

  const getResearchTasks = (ticker: string | undefined) => {
    if (!ticker) {
      return;
    }

    api.post(`/ticker-research/tasks/`, {
      ticker: ticker,
    }).then((response) => {
      const researchTasks = response.data.tasks;
      setBusinessTasks(researchTasks.filter((task: TickerResearchTask) => task.task_type === 'BUSINESS'));
      setFinancialTasks(researchTasks.filter((task: TickerResearchTask) => task.task_type === 'FINANCIALS'));
      setManagementTasks(researchTasks.filter((task: TickerResearchTask) => task.task_type === 'MANAGEMENT'));
      setCompetitorTasks(researchTasks.filter((task: TickerResearchTask) => task.task_type === 'COMPETITORS'));
      setValuationTasks(researchTasks.filter((task: TickerResearchTask) => task.task_type === 'VALUATION'));
    }).catch((error) => {
      setBusinessTasks([])
      setFinancialTasks([])
      setManagementTasks([])
      setCompetitorTasks([])
      setValuationTasks([])
      console.error('Error getting research tasks: ', error);
    });
  }

  const { openTickerAutoComplete, isOpenTickerAutoCompleteReady } = useChatContext();

  if (selectedChatTicker == null) {
    if (isOpenTickerAutoCompleteReady) {
      return (
        <Box>
          <Spacer height="10px"/>
          <Button
            type="submit"
            width="100%"
            variant="outlined"
            onClick={() => openTickerAutoComplete()}
          >
            Add ticker
          </Button>
          <Spacer height="10px"/>
        </Box>
      )
    } else {
      return (
        <Box>
          <Spacer height="10px"/>
          <Text fontSize='14px' isTruncated>
            Add ticker to see research tasks.
          </Text>
        </Box>
      )
    }
  }

  return (
    <Box maxHeight="calc(100vh - 200px)" overflowY="auto">
      <Spacer height="10px"/>
      <TaskRow
        title={"Business Basics"}
        tasks={businessTasks}
      />
      <TaskRow
        title={"Financial Strength"}
        tasks={financialTasks}
      />
      <TaskRow
        title={"Industry Dynamics"}
        tasks={competitorTasks}
      />
      <TaskRow
        title={"Management Quality"}
        tasks={managementTasks}
      />
      <TaskRow
        title={"Valuation"}
        tasks={valuationTasks}
      />
    </Box>
  );
};

type TaskRowProps = {
  title: string;
  tasks: TickerResearchTask[];
};

const TaskRow: React.FC<TaskRowProps> = ({ title, tasks }) => {
  const { sendMessage, isSendMessageFnReady } = useChatContext();

  // If isSendMessageFnReady is false, then the sendMessage function is not ready to be used
  // So just show a loading row

  const onTaskClicked = (task: TickerResearchTask) => {
    if (isSendMessageFnReady) {
      sendMessage({
        sender: 'USER',
        content: task.name,
      })
    }
  }

  if (tasks.length === 0) {
    return <Box/>
  }

  return (
    <Box>
      <Accordion allowMultiple>
        <AccordionItem key={title} border='none' padding={0} py={0}>
          <Box
            cursor="pointer"
            _hover={{ backgroundColor: HoverBackgroundColor }}
          >
            <AccordionButton>
              <Box flex='1'>
                <Flex direction="column" justify="space-between">
                  <HStack spacing="auto">
                    <VStack spacing={1} align="start">
                      <Text fontSize='14px' fontWeight='bold' color={TextColor} isTruncated>
                        {title}
                      </Text>
                      <Text fontSize='14px' color={TextColor} isTruncated>
                        {tasks.length} tasks
                      </Text>
                    </VStack>
                  </HStack>
                </Flex>
              </Box>
              <AccordionIcon color={TextColor}/>
            </AccordionButton>
          </Box>
          <AccordionPanel px={0} py={0}>
            <Box>
              {tasks.map((task, index) => {
                return (
                  <Box
                    key={task.name}
                    cursor="pointer"
                    _hover={{ backgroundColor: HoverBackgroundColor }}
                    paddingX="12px"
                    py={3}
                    onClick={() => onTaskClicked(task)}
                  >
                    <Flex direction="column" justify="space-between" paddingX="12px">
                      <HStack spacing="auto">
                        <VStack spacing={1} align="start">
                          {getTaskName(index, task)}
                        </VStack>
                        <Spacer/>
                      </HStack>
                    </Flex>
                  </Box>
                )
              })}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

const getTaskName = (index: number, task: TickerResearchTask) => {
  if (task.task_type === 'BUSINESS') {
    switch (index) {
      case 0:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Model:</b> {task.name}</Text>;
      case 1:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Moat:</b> {task.name}</Text>;
      case 2:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Risks:</b> {task.name}</Text>;
    }
  } else if (task.task_type === 'FINANCIALS') {
    switch (index) {
      case 0:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Earnings:</b> {task.name}</Text>;
      case 1:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Balance Sheet:</b> {task.name}
        </Text>;
      case 2:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Cash Flow:</b> {task.name}
        </Text>;
    }
  } else if (task.task_type === 'MANAGEMENT') {
    switch (index) {
      case 0:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Leadership:</b> {task.name}
        </Text>;
      case 1:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Performance:</b> {task.name}
        </Text>;
      case 2:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Governance:</b> {task.name}
        </Text>;
    }
  } else if (task.task_type === 'COMPETITORS') {
    switch (index) {
      case 0:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Market:</b> {task.name}</Text>;
      case 1:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Competition:</b> {task.name}
        </Text>;
      case 2:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Positioning:</b> {task.name}
        </Text>;
    }
  } else if (task.task_type === 'VALUATION') {
    switch (index) {
      case 0:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Intrinsic Value:</b> {task.name}
        </Text>;
      case 1:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Market Price:</b> {task.name}
        </Text>;
      case 2:
        return <Text fontSize='14px' color={TextColor}><b style={{ color: TextColor }}>Analysis:</b> {task.name}</Text>;
    }
  }
  return (
    <Text fontSize='14px' color={TextColor}>{task.name}</Text>
  )
}
