import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, HStack, SlideFade, Spacer, useMediaQuery, VStack, } from '@chakra-ui/react';
import { BackgroundColor, Black, Green, TextColor, White } from '../../styles/colors';
import { track } from "@vercel/analytics";
import { useNavigate } from "react-router-dom";

const useTypingEffect = (text: string, speed: number, currentHeadingIndex: number) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    setDisplayedText(''); // Reset text when the heading index changes

    const timeoutId = setInterval(() => {
      setDisplayedText((currentText) => {
        const nextCharIndex = currentText.length;
        if (nextCharIndex < text.length) {
          return currentText + text.charAt(nextCharIndex);
        } else {
          clearInterval(timeoutId); // Make sure to clear interval when done
          return currentText;
        }
      });
    }, speed);

    return () => clearInterval(timeoutId); // Cleanup function to clear interval
  }, [text, speed, currentHeadingIndex]); // Dependency array includes currentHeadingIndex

  return displayedText;
};

export const HomePage = () => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  // An array of heading pairs
  const headingPairs = [
    { topHeading: 'Analyze SEC filings', bottomHeading: 'and create an investment thesis' },
    { topHeading: 'Research companies', bottomHeading: 'and build valuation models' },
    { topHeading: 'Understand industry trends', bottomHeading: 'and identify new investment opportunities' },
  ];

  // State to keep track of the current headings
  const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentHeadingIndex((prevIndex) => (prevIndex + 1) % headingPairs.length);
    }, 4000); // 4 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleLoginClick = () => {
    track('login-clicked', { location: 'splash-page' });
    navigate('/login');
  };

  const handleRegisterClick = () => {
    track('register-clicked', { location: 'splash-page' });
    navigate('/register');
  };


  const bottomHeading = headingPairs[currentHeadingIndex].bottomHeading;
  const animatedBottomHeading = useTypingEffect(bottomHeading, 50, currentHeadingIndex);

  return (
    <Box bg={BackgroundColor} minH="100vh" width="100%">
      {isMobile ? (
        <Flex direction="column" height="100vh" justifyContent="center" alignItems="center">
          <VStack spacing={4}>
            <Heading as="h1" size="lg" pb={2} color={Green}>
              Welcome to Findex
            </Heading>
            <HStack>
              <Button width="100px" colorScheme="green" onClick={handleLoginClick}>Login</Button>
              <Button width="100px" colorScheme="green" onClick={handleRegisterClick}>Register</Button>
            </HStack>
          </VStack>
        </Flex>
      ) : (
        <Box id="page">
          <HStack>
            <Box id="leftPane" flex={6} position="relative" background={Green}>
              <Heading as="h3" size="lg" color={White} position="absolute" top={0} left={0} p={8}>
                Welcome to Findex
              </Heading>
              <Flex flexDirection="column" height="100vh" justifyContent="center">
                <SlideFade in key={currentHeadingIndex} offsetY='20px' transition={{ enter: { duration: 0.5 } }}>
                  <VStack
                    id="prompts"
                    justifyContent="center"
                    alignItems="flex-start"
                    px={8}
                    spacing={4}
                  >
                    <Heading as="h1" size="xl" color={White}>
                      {headingPairs[currentHeadingIndex].topHeading}
                    </Heading>
                    <Heading as="h2" size="xl" fontWeight="normal" color={White}>
                      {animatedBottomHeading}
                    </Heading>
                  </VStack>
                </SlideFade>
              </Flex>
            </Box>
            <Box id="rightPane" flex={4} background={White}>
              <VStack
                justifyContent="center"
                alignItems="center"
                px={8}
                pb={8}
                height="100vh"
              >
                <Heading as="h1" size="lg" pb={2} color={TextColor}>
                  Get started
                </Heading>
                <HStack>
                  <Button width="200px" colorScheme="green" onClick={handleLoginClick}>Login</Button>
                  <Spacer/>
                  <Button width="200px" colorScheme="green" onClick={handleRegisterClick}>Register</Button>
                </HStack>
              </VStack>
            </Box>
          </HStack>
        </Box>
      )}
    </Box>
  );
};
