// ChatPage.tsx

import React from "react";
import { Box } from "@chakra-ui/react";
import ChatLayout from "./ChatLayout";
import LeftSideBar from "../../sidebar/components/left/LeftSideBar";
import RightSideBar from "../../sidebar/components/right/RightSideBar";
import { BackgroundColor } from "../../styles/colors";
import FindexHeader from "../../header/components/FindexHeader";

type ChatPageProps = {};

const ChatPage: React.FC<ChatPageProps> = () => {
  return (
    <Box
      h="100vh"
      w="100%"
      display="flex"
      flexDirection="column"
    >
      <FindexHeader/>
      <Box
        id={"chat-page"}
        display="flex"
        bg={BackgroundColor}
        flex="1"
        overflowY={"auto"}
      >
        <LeftSideBar/>
        <ChatLayout/>
        <RightSideBar/>
      </Box>
    </Box>
  );
};

export default ChatPage;
