export const BackgroundColor = "#ffffff"
export const BorderColor = "#9f9f9f"

export const SelectedBackgroundColor = "#f1f1f1"
export const HoverBackgroundColor = "#f4f4f4"
export const Green = "#18BC9C"
export const Pink = "#E63B5D"
export const Black = "#000000"
export const BlackGray = "#121212"
export const DarkCharcoal = "#333333"
export const Gray = "#515151"
export const TextColor = "#000000"

export const LightGray = "#f4f4f4"

export const White = "#ffffff"