// LoginPage.tsx
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, useToast, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../redux/userSlice';
import { AppDispatch } from '../../store';
import styled from "@emotion/styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { track } from '@vercel/analytics';
import { TextColor } from "../../styles/colors";


interface ErrorResponse {
  detail: string;
}

export const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    track('login-clicked', { location: 'login-page' });

    setIsLoading(true)
    try {
      await dispatch(loginUser({ username, password })).unwrap();
      setIsLoading(false)
      navigate('/');
    } catch (err) {
      const axiosError = err as AxiosError;
      showError(axiosError);
      setIsLoading(false)
    }
  }

  const showError = (axiosError: AxiosError<unknown, any>) => {
    if (axiosError.response) {
      const errorData = axiosError.response.data as ErrorResponse;
      toast({
        title: "Login Error",
        description: errorData.detail, // use the server's error message
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <>
      <Button
        leftIcon={<FontAwesomeIcon icon={faArrowCircleLeft}/>}
        onClick={() => navigate('/')}
        position="absolute"
        top={4}
        left={4}
      >
        Back
      </Button>
      <Container>
        <Box bg="dark" minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
          <Flex
            as="form"
            onSubmit={handleSubmit}
            direction="column"
            align="center"
            position="relative" // To position the Back button
            width="100%" // To take full width
            maxWidth="400px" // Or your preferred width
          >
            <VStack spacing={6} minWidth={"300px"}>
              <Heading as='h3' size='lg' color={TextColor}>Welcome back</Heading>
              <FormControl isRequired>
                <FormLabel color={TextColor}>Username</FormLabel>
                <Input
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  borderRadius="20px"
                  color={TextColor}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel color={TextColor}>Password</FormLabel>
                <Input
                  placeholder="Enter password"
                  type="password" value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  borderRadius="20px"
                  color={TextColor}
                />
              </FormControl>
              <Button type="submit" colorScheme="green" width="100%" isLoading={isLoading}>Submit</Button>
            </VStack>
          </Flex>
        </Box>
      </Container>
    </>
  );
};

const Container = styled(Box)`
  flex: 1;
  overflow: auto; // new
`;
