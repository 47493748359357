// ChatMessage.tsx

import React from 'react';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, LightGray, TextColor } from "../../styles/colors";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";
import { FinancialStatements, MessageMetadata, SourceDocument, SourceDocumentMetadata } from "../../types";
import { faLightbulb, faListNumeric } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  sender: string;
  content: string;
  isUser: boolean;
  metadata?: MessageMetadata;
  queries?: string[];
};

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isUser",
})<{ isUser: boolean }>`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 0.5px solid ${BorderColor};
  background: ${BackgroundColor};
`;

const Bubble = styled.div`
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  width: 75%;
  display: flex;
  align-items: baseline;
  font-size: 16px;
`;

const Content = styled.div`
  margin-left: 14px;
  line-height: 1.5;
  color: black;
  white-space: pre-wrap;
  width: 100%;

  h1, h2, h3, h4, h5, h6 {
    margin: 0.5em 0;
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.75em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.5em;
    font-weight: bold;
  }

  h4 {
    font-size: 1.25em;
    font-weight: bold;
  }

  h5 {
    font-size: 1em;
    font-weight: bold;
  }

  h6 {
    font-size: 0.875em;
    font-weight: bold;
  }
`;

const Sender = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isUser",
})<{ isUser: boolean }>`
  font-weight: 700;
  font-size: 16px;
  min-width: 100px;
  ${(props) => `
    color: ${props.isUser ? TextColor : TextColor};
  `}
`;

export const ChatMessage: React.FC<Props> = ({ sender, content, isUser, metadata, queries }) => {
  // Separate the message into lines
  const lines = content.toString().split("<NEWLINE_TOKEN>");
  const sourceDocuments = metadata ? metadata.source_documents : [];
  const financialStatements = metadata ? metadata.financial_statements : [];

  return (
    <Container isUser={isUser}>
      <Bubble>
        <Sender isUser={isUser}>{capitalizeFirstLetter(sender)}</Sender>
        <VStack width="100%">
          <Content>
            <Box mb={4}>
              <Queries queries={queries || []}/>
            </Box>
            <Box mb={4}>
              {lines.map((line, index) => (
                line === ''
                  ? <br key={index}/>
                  : <div key={index}>{parseText(line)}</div>
              ))}
            </Box>
            <FinancialReports financialStatements={financialStatements || []} sourceDocuments={sourceDocuments || []}/>
          </Content>
        </VStack>
      </Bubble>
    </Container>
  );
};

const Documents = ({ sourceDocuments }: { sourceDocuments: SourceDocument[] }) => {
  if (sourceDocuments.length === 0) {
    return <Box/>
  }

  const getFilingType = (metadata?: SourceDocumentMetadata) => {
    // metadata.
    if (!metadata || !metadata.filing_type) {
      return <Box/>;
    }

    const section = metadata.item_name ? `(${metadata.item_name})` : "";

    if (metadata.filing_type === '10-K') {
      return (
        <Text justifyContent={"left"} align={"left"} fontSize="16px" color={TextColor}>
          <b style={{ color: TextColor }}>Filing: </b>{`10-K from ${metadata.year} ${section}`}
        </Text>
      );
    }

    if (metadata.filing_type === '10-Q') {
      return (
        <Text justifyContent={"left"} align={"left"} fontSize="16px" color={TextColor}>
          <b style={{ color: TextColor }}>Filing: </b>{`10-Q from Q${metadata.quarter} ${metadata.year} ${section}`}
        </Text>
      );
    }
  }

  const getUrl = (metadata?: SourceDocumentMetadata) => {
    if (!metadata || !metadata?.source_url) {
      return <Box/>
    }

    return (
      <Text textAlign="left" fontSize="16px" color={TextColor} isTruncated>
        <b style={{ color: TextColor }}>URL: </b>
        <Link href={metadata?.source_url} isExternal textDecoration="underline">
          {metadata?.source_url}
        </Link>
      </Text>
    );
  }

  const getText = (sourceDocument: SourceDocument) => {
    if (!sourceDocument.text) {
      return <Box/>
    }

    return (
      <Text textAlign="left" fontSize="16px" color={TextColor}>
        <b style={{ color: TextColor }}>Text: </b>{sourceDocument.text}
      </Text>
    );
  }

  return (
    <Box width="100%" borderRadius="20px" py={2}>
      {sourceDocuments.map((sourceDocument, index) => (
        <Box py={1} key={index}>
          <Divider borderColor={TextColor} my={4}/>
          <VStack spacing={1} alignItems="flex-start"> {/* Ensure the VStack aligns items to the start */}
            {getFilingType(sourceDocument.metadata)}
            {getUrl(sourceDocument.metadata)}
            {getText(sourceDocument)}
          </VStack>
        </Box>
      ))}
    </Box>

  )
}

const Queries = ({ queries }: { queries: string[] }) => {
  if (queries.length === 0) {
    return <Box/>
  }

  return (
    <Box width="100%" bg={LightGray} borderRadius="20px" py={2}>
      <Accordion allowMultiple>
        <AccordionItem border='none'>
          <AccordionButton
            width="100%"
            _hover={{ color: TextColor }}
          >
            <FontAwesomeIcon icon={faLightbulb}/>
            <Text as="span" flex='1' textAlign='left' fontSize="16px" pl={2} color={TextColor}>
              Understanding your query...
            </Text>
            <AccordionIcon/>
          </AccordionButton>
          <AccordionPanel>
            {queries.map((query, index) => (
              <Box key={index}>
                <Text key={index} justifyContent={"left"} align={"left"} fontSize="16px" color={TextColor}>
                  • {query}
                </Text>
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

const FinancialReports = ({ financialStatements, sourceDocuments }: {
  financialStatements: FinancialStatements[],
  sourceDocuments: SourceDocument[]
}) => {
  if (financialStatements.length === 0 && sourceDocuments.length === 0) {
    return <Box/>
  }

  // Check if data arrays are non-empty
  const hasSourceDocuments = sourceDocuments.length > 0;

  if (!hasSourceDocuments) {
    return <Box/>
  }

  return (
    <Box width="100%" bg={LightGray} borderRadius="20px" py={2}>
      <Accordion allowMultiple>
        <AccordionItem border='none'>
          <AccordionButton
            width="100%"
            _hover={{ color: TextColor }}
          >
            <FontAwesomeIcon icon={faListNumeric}/>
            <Text as="span" flex='1' textAlign='left' fontSize="16px" pl={2} color={TextColor}>
              Sources
            </Text>
            <AccordionIcon/>
          </AccordionButton>
          <AccordionPanel minHeight="100px">
            <Documents sourceDocuments={sourceDocuments}/>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const parseText = (text: string) => {
  let elements: React.ReactNode[] = [];
  let lastIndex = 0;

  // Split text into lines
  const lines = text.split('\n');

  lines.forEach((line, lineIndex) => {
    const headerMatch = line.match(/^(#{1,6})\s+(.*)$/);
    if (headerMatch) {
      const headerLevel = headerMatch[1].length;
      const headerText = headerMatch[2];
      elements.push(
        React.createElement(`h${headerLevel}`, { key: `header-${lineIndex}` }, headerText)
      );
    } else {
      // Split line by bold markers
      const boldText = line.split("**");
      boldText.forEach((segment, segmentIndex) => {
        if (segmentIndex % 2 === 0) {
          // Split segment by italic markers
          const italicText = segment.split("_");
          italicText.forEach((subSegment, subSegmentIndex) => {
            if (subSegmentIndex % 2 === 0) {
              elements.push(subSegment);
            } else {
              elements.push(<em key={lastIndex}>{subSegment}</em>);
              lastIndex++;
            }
          });
        } else {
          elements.push(<strong key={lastIndex}>{segment}</strong>);
          lastIndex++;
        }
      });
      elements.push(<br key={`br-${lineIndex}`}/>); // Adding a line break for non-header lines
    }
  });

  return elements;
};
