import { Box, Flex, Heading, HStack, Spacer, useToast } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BorderColor, Green, TextColor } from "../../styles/colors";
import { setChatTicker } from "../../redux/chatTickersSlice";
import { TickerMetadataAutoComplete } from "../../autocomplete/TickerMetadataAutoComplete";
import { Chat, TickerMetadata } from "../../types";
import api from "../../auth/apiService";
import { setSelectedChatToken } from "../../redux/chatsSlice";

type Props = {};


export const ChatSearchBar: React.FC<Props> = () => {
  const dispatch = useDispatch();  // Use this to dispatch Redux actions
  const navigate = useNavigate();
  const toast = useToast();

  const onAddTickerClicked = async (tickerMetadata: TickerMetadata) => {
    try {
      let ticker = tickerMetadata.ticker;

      // Get or create chat in BE
      await api.post('v2/chats/get-or-create/', {
        tickers: [ticker]
      });

      // Refresh all chats
      const response = await api.get('v2/chats/');
      const chats = response.data;
      const selectedChat = chats.find((chat: Chat) => chat.tickers?.[0] === ticker);

      if (selectedChat) {
        dispatch(setChatTicker(tickerMetadata));
        dispatch(setSelectedChatToken(selectedChat.token));
        navigate(`?ticker=${ticker}`);
      } else {
        // Handle the case where no chat is found
        console.error('No chat found for the given ticker.');
      }
    } catch (error) {
      console.error('Error handling the ticker addition:', error);
      // Optionally handle the error more specifically or perform some recovery operations
    }

    // Check if we need to upload filings, if yes, then upload filings and show toast
    const response = await api.get('knowledge/should-upload-filings/', {
      params: {
        ticker: tickerMetadata.ticker,
      }
    })

    if (response.data.should_upload) {
      toast({
        title: "Hang tight!",
        description: `We are downloading SEC documents for ${tickerMetadata.ticker}`,
        status: "loading",
        duration: 5000,
        isClosable: true,
      });

      // Upload filings to weaviate
      await api.post('knowledge/upload-filings/', {
        ticker: tickerMetadata.ticker,
      });
    }
  };


  const onOptionSelected = (tickerMetadata: TickerMetadata) => {
    onAddTickerClicked(tickerMetadata);
  }

  return (
    <Box
      paddingBottom={2}
      borderBottom="1px solid"
      borderColor={BorderColor}
      // boxShadow="0px 4px 40px rgba(0, 0, 0, 0.2)"
      zIndex={10}
      width="100%"
    >
      <Flex alignItems="center">
        <Heading
          as="h2"
          size="md"
          color={TextColor}
          marginLeft="24px"
          cursor="pointer"
        >
          Findex
        </Heading>
        <Spacer/>
        <HStack justifyContent="center" alignItems="center" marginLeft="-24px">
          <TickerMetadataAutoComplete
            onOptionSelected={onOptionSelected}
            width={600}
            placeholderText={"Select a ticker to chat..."}/>
        </HStack>
        <Spacer/>
      </Flex>
    </Box>
  );
}