import React from "react";
import { Box } from "@chakra-ui/react";
import { ChatSearchBar } from "../../chat/components/ChatSearchBar";

const FindexHeader: React.FC = () => {
  return (
    <Box width="100%">
      <ChatSearchBar/>
    </Box>
  )
}

export default FindexHeader
