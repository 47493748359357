// store.ts

import { configureStore } from '@reduxjs/toolkit';
import chatsReducer from './redux/chatsSlice';
import chatMessagesReducer from './redux/chatMessagesSlice';
import chatTickersReducer from './redux/chatTickersSlice';
import documentsReducer from './redux/documentsSlice';
import promptsReducer from './redux/promptsSlice';
import tickerMetadataSlice from './redux/tickerMetadataSlice';
import userReducer from './redux/userSlice';

export const store = configureStore({
  reducer: {
    chats: chatsReducer,
    chatMessages: chatMessagesReducer,
    chatTickers: chatTickersReducer,
    tickerMetadata: tickerMetadataSlice,
    documents: documentsReducer,
    prompts: promptsReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
