// RightSideBar.tsx

import React from "react";
import { Box, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { BackgroundColor, BorderColor, TextColor } from "../../../styles/colors";
import { ResearchTab } from "./ResearchTab";

type Props = {};

const RightSideBar: React.FC<Props> = ({}) => {
  return (
    <Box
      w="300px"
      borderLeft="1px solid"
      borderColor={BorderColor}
      bg={BackgroundColor}
    >
      <Tabs variant="unstyled" position="relative">
        <TabList paddingLeft="12px">
          <Tab fontSize="14px">Research</Tab>
          {/*<Tab fontSize="14px">Chat</Tab>*/}
        </TabList>
        <TabIndicator height="2px" bg={TextColor}/>
        <TabPanels>
          <TabPanel>
            <ResearchTab/>
          </TabPanel>
          {/*<TabPanel>*/}
          {/*  <ChatConfigurationTab/>*/}
          {/*</TabPanel>*/}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default RightSideBar;
