// chatTickersSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TickerMetadata } from "../types";

const getInitialTickerMetadata = (): TickerMetadata | null => {
  const params = new URLSearchParams(window.location.search);
  const ticker = params.get('ticker');
  return ticker ? { name: '', ticker } : null;
};

type TickersState = {
  selectedChatTicker: TickerMetadata | null;
  tickers: string[];
};

const initialState: TickersState = {
  selectedChatTicker: getInitialTickerMetadata(),
  tickers: [],
};

export const chatTickersSlice = createSlice({
  name: 'tickerMetadata',
  initialState,
  reducers: {
    setChatTicker: (state, action: PayloadAction<TickerMetadata | null>) => {
      state.selectedChatTicker = action.payload;
    },
    setChatTickers: (state, action) => {
      state.tickers = action.payload;
    }
  },
});

export const { setChatTicker } = chatTickersSlice.actions;  // Export action creator

export default chatTickersSlice.reducer;
