// chatMessagesSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from "../auth/apiService";
import { Message } from "../types";

interface GetChatMessagesArgs {
  chatToken?: string | null;
  chatTicker?: string | null;
}


interface DeleteChatMessagesArgs {
  chatToken: string;
}

export const getChatMessages = createAsyncThunk<Message[], GetChatMessagesArgs, {}>(
  'v2/chats/messages',
  async ({ chatToken, chatTicker }) => {
    const queryParams = new URLSearchParams();

    if (chatToken) {
      queryParams.append('chat_token', chatToken);
    }

    if (chatTicker) {
      queryParams.append('chat_ticker', chatTicker);
    }

    const response = await api.get(`/v2/chats/messages/?${queryParams.toString()}`);
    return response.data;
  }
);


export const deleteMessages = createAsyncThunk<void, DeleteChatMessagesArgs, {}>(
  'v2/chats/delete-messages',
  async ({ chatToken }) => {
    await api.post(`v2/chats/delete-messages/`, { chat_token: chatToken });
  }
);

type ChatMessagesState = {
  messages: Message[],
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
  error: string | null,
};

const initialState: ChatMessagesState = {
  messages: [],
  status: 'idle',
  deleteStatus: 'idle',
  error: null,
};

export const chatMessagesSlice = createSlice({
  name: 'chatMessages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChatMessages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getChatMessages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.messages = action.payload;
      })
      .addCase(getChatMessages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? null;
      })
      .addCase(deleteMessages.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      .addCase(deleteMessages.fulfilled, (state) => {
        state.deleteStatus = 'succeeded';
        state.messages = [];
      })
      .addCase(deleteMessages.rejected, (state, action) => {
        state.deleteStatus = 'failed';
        state.error = action.error.message ?? null;
      });
  },
});
export default chatMessagesSlice.reducer;
