// ChatContext.tsx

import React, { createContext, useContext, useState, useCallback } from 'react';
import { Message } from "../../types";

type ChatContextType = {
  // sendMessage
  isSendMessageFnReady: boolean;
  sendMessage: (message: Message) => void;
  setSendMessageFn: (sendMessageFn: (message: Message) => void) => void;
  // openTickerAutoComplete
  isOpenTickerAutoCompleteReady: boolean;
  openTickerAutoComplete: () => void;
  setOpenTickerAutoCompleteFn: (openTickerAutoComplete: () => void) => void;
};

const ChatContext = createContext<ChatContextType | null>(null);

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }
  return context;
};

type ChatContextProviderProps = {
  children: React.ReactNode; // Define children here
};
export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({ children }) => {
  // sendMessage
  const [sendMessage, setSendMessage] = useState<(message: Message) => void>(() => {});
  const [isSendMessageFnReady, setIsSendMessageFnReady] = useState(false);
  const setSendMessageFn = useCallback((newSendMessage: (message: Message) => void) => {
    setSendMessage(() => newSendMessage);
    setIsSendMessageFnReady(true);
  }, []);

  // openTickerAutoComplete
  const [openTickerAutoComplete, setOpenTickerAutoComplete] = useState<() => void>(() => {});
  const [isOpenTickerAutoCompleteReady, setIsOpenTickerAutoCompleteReady] = useState(false);
  const setOpenTickerAutoCompleteFn = useCallback((newOpenTickerAutoComplete: () => void) => {
    setOpenTickerAutoComplete(() => newOpenTickerAutoComplete);
    setIsOpenTickerAutoCompleteReady(true);
  }, []);

  return (
    <ChatContext.Provider value={{
      sendMessage: sendMessage,
      isSendMessageFnReady: isSendMessageFnReady,
      setSendMessageFn: setSendMessageFn,
      openTickerAutoComplete: openTickerAutoComplete,
      isOpenTickerAutoCompleteReady: isOpenTickerAutoCompleteReady,
      setOpenTickerAutoCompleteFn: setOpenTickerAutoCompleteFn,
    }}>
      {children}
    </ChatContext.Provider>
  );
};
