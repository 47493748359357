import React, { useEffect, useRef, useState } from 'react';
import { Box, HStack, Text } from "@chakra-ui/react";
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

import { createTheme, styled, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createFilterOptions, TextField } from "@mui/material";
import {
  BackgroundColor,
  BorderColor,
  DarkCharcoal,
  Green,
  HoverBackgroundColor,
  TextColor,
  White
} from "../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { TickerMetadata } from "../types";
import { getTickerMetadataList } from "../redux/tickerMetadataSlice";
import { useChatContext } from "../chat/context/ChatContext";


// Define Material UI theme
const muiTheme = createTheme({});

type CustomAutocompleteProps = AutocompleteProps<TickerMetadata, boolean, boolean, boolean>;

type Props = {
  onOptionSelected: (option: TickerMetadata) => void;
  width: number;
  placeholderText?: string,
  initialFocusRef?: React.RefObject<HTMLInputElement>;
};

export const TickerMetadataAutoComplete: React.FC<Props> = ({
  onOptionSelected,
  width,
  placeholderText = "Search ticker",
  initialFocusRef,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const autoCompleteInputRef = useRef<HTMLInputElement>(null);
  const tickerMetadataList = useSelector((state: RootState) => state.tickerMetadata.tickerMetadataList);

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // Load TickerMetadata list from backend
  useEffect(() => {
    dispatch(getTickerMetadataList());
  }, []);

  const handleInputChange = (
    event: React.SyntheticEvent,
    value: string,
  ) => {
    setInputValue(value);
    if (value.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const onTickerSelected = (option: TickerMetadata) => {
    setInputValue(`${option.name} (${option.ticker})`);
    onOptionSelected(option);
    setOpen(false);
  }

  const openTickerAutoComplete = () => {
    setOpen(true);
    // Focus on the input field after opening the autocomplete
    if (autoCompleteInputRef.current) {
      autoCompleteInputRef.current.focus();
    }
  }

  const { setOpenTickerAutoCompleteFn } = useChatContext();

  useEffect(() => {
    setOpenTickerAutoCompleteFn(openTickerAutoComplete);
  }, []);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Box
        width={width}
        bg={BackgroundColor}
      >
        <CustomAutocomplete
          disablePortal
          size="small"
          open={open}
          onClose={() => setOpen(false)}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          PaperComponent={CustomPaper}
          blurOnSelect={true}
          filterOptions={createFilterOptions({ limit: 10 })}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return `${option.ticker} ${option.name}`;
          }}
          options={tickerMetadataList}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder={placeholderText}
              ref={initialFocusRef}
              autoFocus
              InputLabelProps={{ style: { color: '#dcdcdc' }, shrink: true }}
              InputProps={{
                ...params.InputProps,
                inputRef: autoCompleteInputRef,
                startAdornment: (
                  <>
                    {params.InputProps.startAdornment}
                  </>
                )
              }}
            />
          )}
          renderOption={(
            props: React.HTMLAttributes<HTMLLIElement>,
            option: TickerMetadata,
          ) => {
            return (
              <Box
                key={option.ticker}
                bg={BackgroundColor}
                _hover={{ bg: HoverBackgroundColor }}
                cursor="pointer"
                onClick={() => onTickerSelected(option)}
                width={width}
              >
                <HStack p={2} width={width}>
                  <Chip className="ml-2" label={option.ticker} style={{
                    width: "75px",
                    background: Green,
                    color: White,
                    fontWeight: "bold",
                    fontSize: "12px",
                    borderRadius: "6px"
                  }}/>
                  <Box>
                    <Text
                      color={TextColor}
                      width={width - 100}
                      fontSize={14}
                      isTruncated
                    >{`${option.name}`}</Text>
                  </Box>
                </HStack>
              </Box>
            )
          }}
        />
      </Box>
    </MuiThemeProvider>
  );
};

const CustomAutocomplete = styled((props: CustomAutocompleteProps) => <Autocomplete {...props} />)({
  '& .MuiAutocomplete-option': {
    backgroundColor: BackgroundColor,
    '&:hover': {
      backgroundColor: DarkCharcoal,
    },
  },
  '& .MuiAutocomplete-noOptions': {
    color: TextColor,
    backgroundColor: BackgroundColor,
    '&:hover': {
      backgroundColor: DarkCharcoal,
    },
  },
  '& .MuiAutocomplete-clearIndicator': {
    color: TextColor,
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: TextColor,
  },
  '& .MuiOutlinedInput-root': {
    color: TextColor,
    background: BackgroundColor,
    borderRadius: '20px',
    '& .MuiAutocomplete-input': {
      paddingLeft: 8,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: BorderColor,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: TextColor,
      borderWidth: '1px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Green,
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-input': {
      color: TextColor,
    },
    '&:hover .MuiOutlinedInput-input': {
      color: TextColor,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: TextColor,
    },
    '& .MuiInputLabel-outlined': {
      color: TextColor,
    },
    '&:hover .MuiInputLabel-outlined': {
      color: '#181818',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: TextColor,
    },
    '& .MuiSvgIcon-root': {
      color: TextColor,
    },
  },
});

const CustomTextField = styled(TextField)({
  '& .MuiSvgIcon-root': {
    color: TextColor,
  },
  '& .MuiInputBase-root': {
    backgroundColor: BackgroundColor,
    color: TextColor,
    '& input': {
      color: TextColor,
      fontSize: "14px",
    },
    '& label': {
      color: TextColor,
      fontSize: "14px",
    },
    "& input::placeholder": {
      fontSize: "14px",
    },
    '& label.Mui-focused': {
      color: TextColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: Green,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: Green,
      },
      '&:hover fieldset': {
        borderColor: Green,
      },
      '&.Mui-focused fieldset': {
        borderColor: Green,
      },
    },
    '& .MuiSelect-root': {
      color: TextColor,
    },
    '& .MuiSelect-icon': {
      color: TextColor,
    },
  },
});

const CustomPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: BackgroundColor,
  '& .MuiAutocomplete-listbox': {
    backgroundColor: BackgroundColor,
  },
}));
